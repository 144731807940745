import { useLogto } from '@logto/react';
import { useEffect, useState } from 'react';

function App() {
  const { signIn, getAccessToken, signOut, isAuthenticated, isLoading } = useLogto();
  const [accessToken, setAccessToken] = useState(null)
  const [logList, setLogList] = useState(null)
  const [emailList, setEmailList] = useState(null)

  useEffect(() => {
    async function getAPIToken() {
      if (!isLoading && isAuthenticated && !accessToken) {
        const actk = await getAccessToken('https://alive.xineinfra.net/api')
        setAccessToken(actk)
      }
      console.log(accessToken)
    }

    async function fetchEmailList() {
      if (!emailList && accessToken) {
        fetch('https://alive.xineinfra.net/api/email', {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        }).then((response) => response.json())
          .then((data) => {
            console.log(data.data)
            setEmailList(data.data)
          }).catch((error) => {
            console.log(error)
            alert(error.error)
          })
      }
    }

    async function fetchLogList() {
      if (!logList && accessToken) {
        fetch('https://alive.xineinfra.net/api/logs', {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        }).then((response) => response.json())
          .then((data) => {
            setLogList(data.data)
            console.log(data.data)
          })
          .catch((error) => {
            console.log(error)
            alert(error)
          })
      }
    }

    getAPIToken()
    fetchEmailList()
    fetchLogList()
    console.log(accessToken)
    console.log(emailList)
  }, [isLoading, accessToken, isAuthenticated, emailList, getAccessToken])

  async function postEmailList(e) {
    const emailRegex = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
    e.preventDefault()
    for (var i = 0; i < emailList.length; i++) {
      if (!emailRegex.test(emailList[i].email)) {
        alert("One of the email is not valid.")
        return
      }
    }
    // All the emails are valid.
    console.log("Sending API Request")
    fetch('https://alive.xineinfra.net/api/email', {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(emailList)
    }).then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("이메일 주소 업데이트 완료.")
          window.location.reload()
        }
        else {
          alert("개발자 콘솔 확인하세요.")
          console.log(data)
        }
      })
      .catch((error) => {
        console.log(error)
        alert(error.error)
      })
  }

  async function postDocuments(e) {
    e.preventDefault()
    const formData = new FormData(e.target)

    fetch('https://alive.xineinfra.net/api/documents', {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      },
      body: formData
    }).then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("문서 업로드 완료.")
          window.location.reload()
        }
        else {
          alert("개발자 콘솔을 확인하세요.")
          console.log(data)
        }
      })
      .catch((error) => {
        console.log(error)
        alert(error.error)
      })
  }

  const calculateHoursDifference = (timestamp) => {
    const datePlusFourDays = new Date(timestamp);
    datePlusFourDays.setDate(datePlusFourDays.getDate() + 5);

    const currentTime = new Date();
    const diffHours = (datePlusFourDays - currentTime) / 1000 / 60 / 60;
    return diffHours.toFixed(2);
  };

  return (
    <div style={{ backgroundColor: "#2b2b2b" }}>
      {
        !isLoading ? (
          isAuthenticated ? (
            <div className="body">
              <div className="emailadddiv">
                <h3 className="emailaddheader3">이메일 주소 리스트 </h3>
                <div className="w-form">
                  <form onSubmit={postEmailList}>
                    {
                      emailList ? (
                        emailList.map((item, index) => {
                          return (
                            <div className="emailinputwrapperdiv" key={index}>
                              <input className="emailtextfield w-input" maxlength="256" placeholder="이메일 주소" type="text" value={item.email} onChange={e => {
                                setEmailList(currentEmailList =>
                                  currentEmailList.map((emailItem, idx) =>
                                    idx === index ? { ...emailItem, email: e.target.value } : emailItem
                                  )
                                );
                              }}
                                readOnly={item.email === 'default@default.com'}
                                required="" />
                              <a onClick={() => setEmailList(emailList => [...emailList, { email: '', default: true, previous: false }])} className="emailaddlink w-inline-block">추가</a>
                            </div>
                          )
                        })
                      ) : (
                        <div></div>
                      )
                    }
                    <input type="submit" data-wait="" className="emailsubmitbtn w-button" value="리스트 변경" />
                  </form>
                </div>
              </div>
              <div className="documenadddiv">
                <h3 className="emailaddheader3">발송 파일 리스트 </h3>
                <div className="w-form">
                  <form onSubmit={postDocuments}>
                    {
                      emailList ? (
                        emailList.map((item, index) => {
                          return (
                            <div className="fileinputwrapperdiv">
                              <label for={item.prev} className="emailaddresstext">{item.prev}에 발송될 문서
                                {
                                  item.default ? (
                                    <strong>  (Default: YES)</strong>
                                  ) : (
                                    <strong>  (Default: NO)</strong>
                                  )
                                }
                              </label>
                              <input style={{ width: "100%" }} type="file" name={item.prev} id={item.prev} className="fileinputbtn w-button" content="파일 추가" accept=".pdf" />
                            </div>
                          )
                        })
                      ) : (
                        <div></div>
                      )
                    }
                    <input type="submit" className="filesubmitbtn w-button" value="파일 변경" />
                  </form>
                </div>
              </div>
              <div className="logshowdiv">
                <h3 className="emailaddheader3">활동 로그 </h3>
                <div className="lastactivitytext">마지막 활동은 <strong>{logList && logList.length > 0 ?
                  logList[logList.length - 1].actionTime :
                  "데이터 없음"
                }</strong> 입니다. 문서발송까지 <strong>{
                  logList && logList.length > 0 ?
                    calculateHoursDifference(logList[logList.length - 1].actionTime) + "시간" :
                    "시간 데이터 없음"
                }</strong> 남았습니다. </div>
                <div className="w-layout-grid loggrid">
                  {
                    logList ? (
                      logList.map((item, index) => {
                        return (
                          <div class="loggridwrapperdiv">
                            <div id="w-node-c0cdde33-3068-4ccc-9327-678c01b2a36c-2fdddb5c" class="logshowwrapperdiv">
                              <div>{item.deviceKey}</div>
                            </div>
                            <div id="w-node-d444a5b9-7eed-6555-3ebb-4976701a0b22-2fdddb5c" class="logshowwrapperdiv">
                              <div>{item.actionTime}</div>
                            </div>
                            <div id="w-node-_4c481e5e-a4db-2832-297b-69d50d982357-2fdddb5c" class="logshowwrapperdiv">
                              <div>{item.action}</div>
                            </div>
                            <div id="w-node-_97932626-029a-38b4-94e4-dff014fa6b2c-2fdddb5c" class="logshowwrapperdiv">
                              <div>{item.ipAddr}</div>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <div></div>
                    )
                  }
                </div>
                <div className="disclaimertext">최근 24시간의 로그만 보여집니다. <a style={{ textDecoration: "underline" }} onClick={() => signOut('https://justi.es/')}>로그아웃하기</a></div>
              </div>
            </div>
          ) : (
            signIn('https://alive.xineinfra.net/callback')
          )
        ) : (
          <div>
            로그인이 오래 걸린다면: <a style={{ textDecoration: "underline" }} onClick={() => signOut('https://justi.es/')}>로그아웃</a>
          </div>
        )
      }
    </div>
  );
}

export default App;
