import { useHandleSignInCallback } from '@logto/react';
import { useNavigate } from 'react-router-dom';

function CallBack () {
    const navigate = useNavigate()

    const { isLoading } = useHandleSignInCallback(() => {
        navigate("/")
    })

    if (isLoading) {
        return <div>Redirecting...</div>
    }

}

export default CallBack 