import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import CallBack from './CallBack';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LogtoProvider, UserScope } from '@logto/react';

const config = {
  endpoint: 'https://j1mvqk.logto.app/',
  appId: 'y7jq703vmlevng9666hpi',
  resources: ['https://alive.xineinfra.net/api'],
  scopes: [
    UserScope.Email
  ],
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <LogtoProvider config={config}>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<App />} />
        </Route>
        <Route path="callback" element={<CallBack />} />
        { /* No Page Exists, 404 */}
        <Route path="*" />
      </Routes>
    </BrowserRouter>
  </LogtoProvider>
);

reportWebVitals();
